

.App {
  /* text-align: center; ----------------------------------------- */
  z-index: -1;
  animation: fadeIn 900ms ease-in-out;

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header{
  background-color:#FF3F6E ;
  height:50px;
  width: 100vw;
}
.header h2{
  color: white;
  line-height:50px;
  margin-left: 35px;
  
}
@media screen and (min-width:1500px){
  .header h2{
    font-size: 40px;
    line-height:70px;
  }
  .header{
    height: 70px;
  }
}
/* mainsection */
.main-section-background{
  background-image: url("https://cdn.create.vista.com/api/media/medium/141696440/stock-photo-beautiful-girl-with-long-wavy-hair?token=");
  background-repeat: no-repeat; 
  background-position: center;
  background-size: cover;
  height: 80vh;
  width: 100vw;
  padding: 30px;
  display: flex;
  animation: fadeIn 900ms ease-in-out;
  /* border: 2px solid black; */
  /* z-index: ; */
  position:relative;
}
.mainSection{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* animation: fadeIn 900ms ease-in-out; */
  /* padding:20px 0px;   */
  z-index: 1;
}
.mainSection2{
/* height: 50%; */
display: none;
background: transparent;
/* border: 2px solid black; */
backdrop-filter: blur(6px);
position:absolute;
bottom:20% ;
left: 25%;
animation: fadeIn 900ms ease-in-out;
border-radius: 5px;
/* color:#FF3F6E; */

}
.mainSection3{
  display: none;
  background: transparent;
  /* border: 2px solid black; */
  backdrop-filter: blur(10px);
  position:absolute;
  bottom:20px ;
  left: 25%;
  color:white;
  animation: fadeIn 900ms ease-in-out;
  padding: 10px;
  border-radius: 5px;
}
@keyframes fadeIn {
  from{opacity: 0;}
  to{opacity: 1;}
}
.fade-in{
  animation: fadeIn 900ms ease-in-out;
}
.mainSection h1{
  margin: 30px;
  font-weight: 700;
  font-size: 4vw;
  text-align: center;
}
.mainSection h3{
  font-weight: 400;
  font-size: 2vw;
  text-align: center;
}
.apple-logo{
  height: 20px;
  width: 20px;
  margin: 0 5px;
}
.buttonDiv{
display: flex;
align-items: center;
justify-content: center;
gap: 30px;
margin: 20px;
/* animation: fadeIn 900ms ease-in-out; */

}
.button{
  text-align: center;
  background-color: black;
  color: white;
  text-decoration: none;
  height: 35px;
  width: 120px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 3px 3px 3px #0101015d;
  cursor: pointer;
  &:hover{
    transform: scale(1.2);
  }
}

.circles{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 10px;
  
}
.circle{
height: 12px;
width: 12px;
border: 1px solid #FF3F6E;
border-radius: 50%;
background-color: white;
}
#circle1{
background-color:#FF3F6E;
}
@media screen and (max-width:800px){
  .main-section-background{
    height: 45vh;
    padding: 0;
  }
  .mainSection{
    gap: 5px;
    padding: 0;
  }
  .mainSection h1{
    margin: 0;
    /* width: 100vw; */
    font-size: 4vw;
  }
  .mainSection h3{
    font-size: 2vw;
    /* width: 300px; */
  }
  button{
    transform: scale(0.9);
    margin: 0;
  }
  .buttonDiv{
    /* gap: 0px; */
    margin:0px;
    transform: scale(0.5);
  }
  .header{
    height: 40px;
  }
  .header h2{
    line-height: 40px;
    text-align: center;
    margin: 0;
  }
  
}
/* client-section --------------------------------------*/
.client-section{
display: flex;
margin-top: 30px;
/* height: 500px; */
/* border: 1px solid black; */
align-items: center;
}
.client-info{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  padding: 0 60px;
}
.client-info h2{
  max-width: 35vw;
  text-align: center;
  font-size: 30px;
  font-size: 3.5vw;
  margin-bottom: 20px;
}
.client-info h3{
font-size: 22px;
font-size: 2vw;
font-weight: 500;
max-width: 550px;
max-width: 35vw;
margin-bottom: 25px;
}
.client-info h4{
  font-size: 22px;
  font-size: 2vw;
  font-weight: 350;
  max-width: 550px;
  max-width: 35vw;
}
.client-img{
  width: 50%;
  
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  
}
.clientImgCollage{
height: 35vw;
width: 40vw;
/* border: 2px solid black; */
position: relative;


}
#clientImg{
  border: 2px solid white;
}
.clientImg1{
height: 160px;
width: 140px;
height:14vw;
width:12vw ;
position: absolute;
top: 170px;
top: 15vw;
left: 180px;
left:15vw;
z-index: 1;
transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.clientImg2{
height: 200px;
width: 180px;
height:16vw ;
width: 14vw;
bottom: -30px;
top: 17vw;
left: 20px;
left: 2vw;
position: absolute;
transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.clientImg3{
 height: 200px;
 width: 300px;
 height:16vw ;
 width: 23vw;
 position: absolute;
 top: 0px;
 left: 0;
 transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.clientImg4{

height: 300px;
width: 270px;
width: 15vw;
height: 20vw;
position: absolute;
left: 310px;
left: 23vw;
top: 70px;
top: 5vw;
transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
@media screen and (min-width:800px) {
  .client-info .clientImgCollage{
    display: none;
  }

}
@media screen and (max-width:800px) {
  .client-img{
    display: none;
  }
  .client-info{
    width: 100%;
  }
  #clientImg{
    transform: scale(1.5);
  }
  .client-info{
    padding: 0 20px;
  }
  .client-info h3{
    font-size: 3vw;
    max-width: 90%;
    text-align: center;
  }
  .client-info h4{
    font-size: 3vw;
    max-width: 90%;
    text-align: center;
  }
  .client-info h2{
    font-size: 4vw;
  }
}
/* our services ------------------------------------------------------ */
.mainServices{
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 130px;s̄ */
  gap: 3vw;
  justify-content: space-evenly;
  background-color: #FF3F6E;
  margin-top: 20px;

}
.mainServices h1{
  text-align: center;
  font-size: 4vw;
  font-weight: 700;
  /* text-decoration:underline; */
  border-bottom: 3px solid white;
  color: white;
  
}
.cardContainer{
  width: 100%;
  display: flex;
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
  flex-flow:row;
  overflow-x:scroll;
  gap: 20px;
  /* margin-left: 20px; */
  padding: 20px 20px 20px 20px;
  
}
.cardContainer .card{
  /* z-index: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  width: 15vw;
  min-width:120px ;
  height: 240px;
  height: 19vw;
  min-height: 150px;
  background-color: white;
  border-radius: 13px;
  box-shadow: 3px 3px 3px #01010149;
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.cardDetails .cardDescription{
  text-align: center;
  font-size: 14px;
  font-size: 8px;
  font-weight: 500;
  padding:10px;
  padding-bottom: 20px;
}
.imgDiv{
  height: 135px;
  height: 10vw;
  min-height: 80px;
  width: 100%;
  /* border-radius: 13px; */
  background-color: #FF3F6E;
  z-index: 1;
  /* position: relative; */
}
.card img{
  height: 100%;
  width: 100%;
  border: 3px solid white;
  z-index: -1;
  /* position: absolute; */
  border-radius: 13px 13px 0 0;
}
.cardHeading{
  font-size: 2vw;
  font-size: 12px;
  font-weight: 600;
}

.cardDetails .cardHeading{
  text-align: center;
}
@media screen and (min-width:560px){
  .cardContainer{
    justify-content: space-evenly;
    margin-left: 0;
    padding: 30px 0;
  }
  .mainServices{
    height: 50vw;
  }
  .cardHeading{
    font-size: 2vw;
  }
  .cardDetails .cardDescription{
    font-size: 1.2vw;
  }
}
@media screen and (max-width:700px){
  .mainServices{
    /* height:65vw; */
  }
  .mainServices h1{
    font-size: 5vw;
  }
  .cardContainer{
    /* flex-direction: column; */
    /* width: 60vw; */
    /* gap: 15px; */
  }
  .cardContainer .card{
    /* transform: scale(1.2); */
    /* flex-direction: row; */
    /* width: 60vw; */
    /* height: 14vw; */
    /* border-radius: 0; */
  }
  .imgDiv{
    /* height: 100%; */
    /* width: 22vw; */
    /* border-radius: 0px; */
  }
  .card img{
    /* height: 100%; */
    /* border-radius: 1.5vw 0 0 1.5vw; */
    
  }
  .cardHeading{
    /* font-size: 3vw; */
    /* padding: 0; */
  }
  .cardDetails{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw; */
  }
  .cardDetails .cardDescription{
    /* font-size: 2vw; */
    /* padding: 0; */
  }
}
/* testimonials----------------------------------------------------------- */
.testimonials{
  /* border: 2px solid black; */
  padding: 30px 0;
  height: 680px;
  /* height: 80vw; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  /* overflow: hidden; */
  /* overflow:scroll; */
  /* overflow-x: auto; */
  /* background-color: #FF3F6E; */
  /* margin-top: 20px; */
  position: relative;
}
.testimonials .heading{
  text-align: center;
  /* width: 250px; */
  /* width: 100vw; */
  font-size: 70px;
  font-size: 4vw;

  font-weight: 700;
  color: #FF3F6E;
  /* text-decoration:underline; */
  border-bottom: 3px solid #FF3F6E;
}
.testimonials .description{
  text-align: center;
  font-size: 40px;
  font-size: 2.5vw;
  margin-bottom: 20px;
}

.feedbackCardContainer{
  /* position: absolute; */
  /* top: 230px; */
  top: 20vw;
  left: 00px;
  width: 100%;
  padding: 0px 20px;
  /* max-width: 1000px; */
  height: 400px;
  /* border: 2px solid black; */
  display: flex;
  flex-wrap: wrap;
  flex-flow:row;
  overflow-x:scroll;
  /* overflow-x:auto; */
  /* overflow-x: scroll; */
  /* flex-wrap:inherit; */
  justify-content: space-between;
  gap: 20px;
}
@media screen and (min-width:1500px) {
  .feedbackCardContainer{
  justify-content: space-evenly;
  }
}
.feedbackCard:nth-child(1){
  /* border: 5px solid black; */
}

.feedbackCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  /* min-width: 33.33%; */
  height: 250px;
  /* height: 65vw; */
  margin: 10px;
  background-color: white;
  /* border-radius: 13px; */
  /* border: 2px solid black; */
  position: relative;
  top: 65px;
  /* top: 7vw; */
  /* left: 100px; */
  background-color: #D9D9D9;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #9e9c9cb0;
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.feedbackCard img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 7px solid white;
  position: absolute;
  top: -50px;
}
.feedbackCard  .comment .quotes{
  font-size: 25px;
  font-family:monospace;
  color: #FF3F6E;
  text-align: left;
  line-height: 5px;
}
.feedbackCard  .comment .quotesend{
  font-size: 25px;
  font-family:monospace;
  color: #FF3F6E;
  text-align:right;
  /* line-height: 50px; */
  position: relative;
  top:-20px ;
  left: 5px;
  
}
.feedbackCard .comment{
  position: absolute;
  top: 50px;
  text-align: center;
  width: 93%;
  line-height: 22px;
  font-size: 16px;
  color: black;
}
.feedbackCard .comment .span{
  font-size: 25px;
  font-family:monospace;
  color: #FF3F6E;
  text-align: left;
  line-height: 5px;
}
.feedbackCard .typewriter{
  display: none;
}
.feedbackCard .name{
  position: absolute;
  bottom: 10px;
  color: #FF3F6E;
  font-size: 18px;
  font-weight: 600;
}

.feedbackCard .name span{
  font-size: 12px;
  color: black;
  font-weight: 600;
}
@media screen and (min-width:2120px){
  .testimonials{
    height: 800px;
  }
}
@media screen and (max-width:1100px){
  .testimonials{
    height: 600px;
  }
}
@media screen and (max-width:700px){
  .feedbackCard{
    height: 200px;
    min-width: 220px;
    
  }
  .feedbackCard .comment{
    font-size: 12px;
    line-height: 16px;
    top: 40px;
  }
  .feedbackCard .name{
    font-size: 13px;
    bottom: 5px;
  }
  .feedbackCard .name span{
    font-size: 9px;
  }
  .feedbackCard .comment .span{
    font-size: 15px;
  }
  .feedbackCard  .comment .quotes{
    font-size: 15px;
  }
  .feedbackCard  .comment .quotesend{
    font-size: 15px;
  }
  .feedbackCard img{
    height: 80px;
    width: 80px;
    top: -40px;
  }
  .feedbackCardContainer{
    height: 320px;
  }
  .testimonials{
    height: 500px;
  }
  .testimonials .heading{
    font-size: 5vw;
  }
  .testimonials .description{
    font-size: 3vw;
  }
}
@media screen and (max-width:480px){
  .testimonials{
    height: 450px;
  }
}
/* about us section------------------------------ */

.mainAboutUs{
  /* padding: 50px; */
  height: 620px;
  height: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* width: 100%; */
}
.mainAboutUs .aboutHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.mainAboutUs .aboutHeader .heading1{
  text-align: center;
  font-size: 42px;
  font-size: 3.5vw;
  font-weight: 700;
  color: #FF3F6E;
  /* text-decoration:underline; */
  border-bottom: 3px solid #FF3F6E;
}
.mainAboutUs .aboutHeader .description{
  text-align: center;
  font-size: 30px;
  font-size: 2.5vw;
  margin-bottom: 20px;
}
.aboutUsBody{
  display: flex;
  width: 100%;
  /* border: 1px solid black; */
  height: 100%;
}
.counting{
  width: 50%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d984;
}
.countCardContainer{
  display: flex;
  width: 40vw;
  flex-wrap: wrap;
  gap: 30px;
  font-size: 20px;
  font-size: 2vw;
  
  font-weight: 600;
}
.countCard{
  height: 130px;
  height: 15vw;
  width: 130px;
  width: 15vw;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;  
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.countCard .name{
  text-align: center;
}
.countCard:nth-child(2),.countCard:nth-child(3){
  background-color: white;
  color: black;
  border: 1px solid #FF3F6E;
  }
.countCard:nth-child(1){
background-color: #FF3F6E;
color: white;
}
.countCard:nth-child(4){
  background-color: #FF3F6E;
  color: white;
  }
.countCard img{
  height: 60px;
  height: 7vw;
  width: 60px;
  width: 7vw;
}
.aboutUsInfo{
  display: flex;
  flex-direction: column;
align-items: center;
/* justify-content: center; */
gap: 50px;
gap: 5vw;
padding-top: 40px;
width: 50%;
  /* border: 1px solid black; */
  color: white;
  background-color: #FF3F6E;
}
.aboutUsInfo .heading2{
  text-align: center;
  font-size: 2.5vw;
  font-weight: 600;
}
.aboutUsInfo .description2{
  width: 80%;
  font-size: 1.5vw;
  font-weight: 400;
}
.aboutUsInfo .contact{
  /* line-height: 30px; */
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.contact .contactHeading{
  text-align: center;
  font-size: 2.3vw;
  font-weight: 600;
}
.contact .phone , .contact .mail{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2vw;
}
.mail img, .phone img{
height: 3vw;
width: 3vw;
}
@media screen and (max-width:1000px){
  .countCard{
    padding: 1vw;
    gap: 2px;  
  }
}
@media screen and (max-width:700px){
  .counting{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .aboutUsInfo .description2{
    font-size: 2vw;
    width: 90%;
  }
  .aboutUsInfo{
    width: 70%;
  }
  .mainAboutUs{
    height: unset;
  }
  .countCardContainer{
    width: 15vw;
  }
  .mainAboutUs .aboutHeader .heading1{
    font-size: 4vw;
  }
  .countCardContainer{
    gap: 2vw;
    /* height: 90%; */
    margin: 10px 0px;
  }
}
@media screen and (max-width:500px){
  .countCard{
    padding: 1vw;
    gap: 1px;  
  }
  
}

/* footer------------------------ */
.footer{
  height: 60px;
  height: 6vw;
  width: 100%;
  background-color: #FF3F6E;
  position: relative;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footerText{
  font-size: 2vw;
  font-weight: 600;
  text-align: center;
  /* line-height: vw; */
  color: white;
}
.footer .footerText a{
color: white;
}
.footer .socialIcon{
  position: absolute;
  right: 2vw;
  top: 1vw;

}
.image{
  height: 4vw;
  width: 4vw;
  margin-right: 2vw;
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.3);
  }
}
@media screen and (max-width:600px){
  .footer{
    height: 8vw;
  }
  .footer .socialIcon{
    top: 2vw;

  }
  .footer .footerText{
    font-size: 2.5vw;
  }
}
@media screen and (max-width:500px){
  .footer{
    height: 10vw;
  }
  .footer .socialIcon{
    top: 3vw;

  }
}